import React, { useState, useEffect } from 'react';
import './CharacterSheet.css';

// Helper function to parse and format D&D reference data
const formatDndReferences = (text) => {
  if (!text) return '';
  
  // Format @variantrule references 
  text = text.replace(/@variantrule\[(.*?)\|(.*?)\]/g, (match, name) => {
    return `<span class="dnd-reference">${name}</span>`;
  });
  
  // Format @item references
  text = text.replace(/@item\[(.*?)\|(.*?)\]/g, (match, name) => {
    return `<span class="dnd-reference">${name}</span>`;
  });
  
  // Format @UUID references (simplify them)
  text = text.replace(/@UUID\[(.*?)\]/g, () => {
    return `<span class="dnd-reference">Item</span>`;
  });
  
  // Format condition references
  text = text.replace(/@Reference\[condition=(.*?)\]/g, (match, condition) => {
    return `<span class="dnd-reference">${condition}</span>`;
  });
  
  // Format simple @ references
  text = text.replace(/@([a-zA-Z]+)/g, (match, term) => {
    return `<span class="dnd-reference">${term}</span>`;
  });
  
  return text;
};

const CharacterSheet = ({ userId }) => {
  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState('');
  const [activeTab, setActiveTab] = useState('info');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  
  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';

  useEffect(() => {
    console.log('CharacterSheet component mounted with userId:', userId);
    fetchCharacter();
  }, [userId]);
  
  useEffect(() => {
    // Save character to localStorage whenever it changes and we're in demo mode
    if (character && unsavedChanges) {
      const storageKey = getStorageKey();
      console.log(`Saving character to localStorage with key: ${storageKey}`);
      try {
        const characterJson = JSON.stringify(character);
        localStorage.setItem(storageKey, characterJson);
        console.log(`Character "${character.name || 'Unnamed'}" saved to localStorage (${characterJson.length} bytes)`);
      } catch (err) {
        console.error('Error saving to localStorage:', err);
      }
    }
  }, [character, unsavedChanges, userId]);
  
  const fetchCharacter = async () => {
    try {
      setLoading(true);
      setDebugInfo('Attempting to fetch character data...');
      
      // If userId is 'new', load an empty character
      if (userId === 'new') {
        setDebugInfo('Creating new character...');
        loadSampleData();
        return;
      }
      
      // IMPORTANT CHANGE: Always try to fetch from server FIRST
      // Check if we have an auth token
      const authToken = localStorage.getItem('auth_token');
      if (authToken) {
        try {
          // Log the actual userId being used
          console.log('Fetching character data from SERVER for userId:', userId);
          setDebugInfo(debugInfo + `\nFetching from server: ${API_URL}/characters/${userId}`);
          
          // Fetch character data from server
          const response = await fetch(`${API_URL}/characters/${userId}`, {
            headers: {
              'x-auth-token': authToken
            },
            // Add cache-busting query parameter to force fresh data
            cache: 'no-store'
          });
          
          console.log('API response status:', response.status);
          setDebugInfo(debugInfo + `\nAPI response status: ${response.status}`);
          
          if (response.ok) {
            const data = await response.json();
            console.log('Character data loaded successfully from SERVER:', data.name || 'Unnamed Character');
            setDebugInfo(debugInfo + '\nCharacter data loaded successfully from SERVER');
            
            // We got data from the server, save it to localStorage also
            const storageKey = getStorageKey();
            localStorage.setItem(storageKey, JSON.stringify(data));
            
            setCharacter(data);
            setError(null);
            setUnsavedChanges(false);
            setLoading(false);
            return; // Got server data successfully, all done
          } else {
            console.log(`Server returned ${response.status}, will try localStorage next`);
            setDebugInfo(debugInfo + `\nServer returned ${response.status}, trying localStorage next`);
            // If server fetch failed, continue to try localStorage below
          }
        } catch (serverError) {
          console.error('Error fetching from server:', serverError);
          setDebugInfo(debugInfo + `\nError fetching from server: ${serverError.message}, trying localStorage next`);
          // If server fetch failed, continue to try localStorage below
        }
      } else {
        console.log('No auth token, skipping server fetch');
        setDebugInfo(debugInfo + '\nNo auth token, skipping server fetch');
      }
      
      // FALLBACK: If server fetch failed or no auth token, try localStorage
      const storageKey = getStorageKey();
      console.log(`Trying localStorage for key: ${storageKey}`);
      setDebugInfo(debugInfo + `\nTrying localStorage for key: ${storageKey}`);
      const savedCharacter = localStorage.getItem(storageKey);
      
      if (savedCharacter) {
        try {
          const parsedCharacter = JSON.parse(savedCharacter);
          console.log('Found character in localStorage:', parsedCharacter.name || 'Unnamed Character');
          setDebugInfo(debugInfo + `\nLoaded character from localStorage: ${parsedCharacter.name || 'Unnamed'}`);
          setCharacter(parsedCharacter);
          setError(null);
          setUnsavedChanges(false);
          setLoading(false);
          return;
        } catch (e) {
          console.error('Error parsing saved character:', e);
          setDebugInfo(debugInfo + `\nError loading from localStorage: ${e.message}`);
        }
      } else {
        console.log('No saved character found in localStorage');
        setDebugInfo(debugInfo + '\nNo saved character found in localStorage');
      }
      
      // As last resort, try sample data
      console.log('Falling back to sample data');
      setDebugInfo(debugInfo + '\nFalling back to sample data');
      loadSampleData();
    } catch (err) {
      console.error('Error in fetchCharacter:', err);
      setDebugInfo(debugInfo + `\nError: ${err.message}`);
      setError(`Failed to load character data: ${err.message}`);
      
      // For demo/development - load sample data
      loadSampleData();
    } finally {
      setLoading(false);
    }
  };
  
  const getStorageKey = () => {
    return `character_data_${userId || 'default'}`;
  };
  
  const loadSampleData = async () => {
    try {
      setDebugInfo(debugInfo + '\nAttempting to load sample data');
      
      // First check if we have a saved character in localStorage
      const storageKey = getStorageKey();
      console.log(`Checking localStorage for key: ${storageKey}`);
      const savedCharacter = localStorage.getItem(storageKey);
      
      if (savedCharacter) {
        try {
          const parsedCharacter = JSON.parse(savedCharacter);
          console.log('Found character in localStorage:', parsedCharacter.name || 'Unnamed Character');
          setDebugInfo(debugInfo + `\nLoaded character from localStorage: ${parsedCharacter.name || 'Unnamed'}`);
          setCharacter(parsedCharacter);
          setError(null);
          setUnsavedChanges(false); // Already saved to localStorage
          return;
        } catch (e) {
          console.error('Error parsing saved character:', e);
          setDebugInfo(debugInfo + `\nError loading from localStorage: ${e.message}`);
        }
      } else {
        console.log('No saved character found in localStorage');
        setDebugInfo(debugInfo + '\nNo saved character found in localStorage');
      }
      
      // Try multiple possible paths to find the sample data
      const possiblePaths = [
        '/src/assets/data/Ching Pow030825.json',
        '/assets/data/Ching Pow030825.json',
        './assets/data/Ching Pow030825.json',
        '../assets/data/Ching Pow030825.json'
      ];
      
      let data = null;
      let loadedPath = '';
      
      // Try each path until one works
      for (const path of possiblePaths) {
        setDebugInfo(debugInfo + `\nTrying to load from: ${path}`);
        try {
          const response = await fetch(path);
          if (response.ok) {
            data = await response.json();
            loadedPath = path;
            setDebugInfo(debugInfo + `\nSuccessfully loaded from: ${path}`);
            break;
          }
        } catch (e) {
          console.log(`Failed to load from ${path}: ${e.message}`);
        }
      }
      
      // If we couldn't load the sample data, create a basic character
      if (!data) {
        setDebugInfo(debugInfo + '\nCould not load sample data, creating empty character');
        data = {
          name: 'New Character',
          system: {
            abilities: {
              str: { value: 10, proficient: 0 },
              dex: { value: 10, proficient: 0 },
              con: { value: 10, proficient: 0 },
              int: { value: 10, proficient: 0 },
              wis: { value: 10, proficient: 0 },
              cha: { value: 10, proficient: 0 }
            },
            skills: {},
            details: {
              race: '',
              background: '',
              alignment: ''
            },
            attributes: {
              hp: { value: 10, max: 10 }
            },
            currency: {
              pp: 0,
              gp: 0,
              ep: 0,
              sp: 0,
              cp: 0
            }
          },
          items: []
        };
      } else if (data.system && data.system.abilities && data.system.abilities.cha) {
        // Fix the Charisma score to 10 as mentioned
        data.system.abilities.cha.value = 10;
      }
      
      setCharacter(data);
      setError(null);
      setUnsavedChanges(true); // Mark as unsaved since we're loading sample data
    } catch (err) {
      console.error('Error in loadSampleData:', err);
      setDebugInfo(debugInfo + `\nError in loadSampleData: ${err.message}`);
      
      // Create a basic character as a last resort
      const emptyCharacter = {
        name: 'New Character',
        system: {
          abilities: {
            str: { value: 10, proficient: 0 },
            dex: { value: 10, proficient: 0 },
            con: { value: 10, proficient: 0 },
            int: { value: 10, proficient: 0 },
            wis: { value: 10, proficient: 0 },
            cha: { value: 10, proficient: 0 }
          }
        }
      };
      setCharacter(emptyCharacter);
    }
  };
  
  const handleInputChange = (section, field, value) => {
    setCharacter(prevCharacter => {
      // Create a deep copy of the character to avoid mutation
      const updatedCharacter = JSON.parse(JSON.stringify(prevCharacter));
      
      // Handle nested properties
      if (section.includes('.')) {
        const parts = section.split('.');
        let current = updatedCharacter;
        
        // Navigate to the nested object
        for (let i = 0; i < parts.length - 1; i++) {
          if (!current[parts[i]]) {
            current[parts[i]] = {};
          }
          current = current[parts[i]];
        }
        
        // Update the field
        current[parts[parts.length - 1]][field] = value;
      } else {
        // Top-level property
        if (!updatedCharacter[section]) {
          updatedCharacter[section] = {};
        }
        updatedCharacter[section][field] = value;
      }
      
      setUnsavedChanges(true);
      return updatedCharacter;
    });
  };
  
  const saveCharacter = async () => {
    try {
      // First save to server if we have auth, as the server is the primary source of truth
      const authToken = localStorage.getItem('auth_token');
      const storageKey = getStorageKey();
      
      // Make sure the character has an ID if it's new
      const characterToSave = {
        ...character,
        id: character.id || `demo-${Date.now()}`
      };
      
      // Attempt to save to server as priority
      if (authToken) {
        const isNew = !character.id || userId === 'new' || character.id.toString().startsWith('demo-');
        const method = isNew ? 'POST' : 'PUT';
        const url = isNew 
          ? `${API_URL}/characters` 
          : `${API_URL}/characters/${character.id}`;

        console.log(`Saving character data to SERVER for userId ${userId}`);
        console.log(`Using ${method} request to ${url}`);
        setDebugInfo(prevDebug => prevDebug + `\nSaving character to SERVER using ${method} to ${url}`);

        try {
          const characterString = JSON.stringify({
            ...characterToSave,
            // Make sure userId is included
            userId: userId !== 'new' ? userId : undefined,
            // Add timestamp to track the last update
            lastUpdated: new Date().toISOString()
          });
          
          const response = await fetch(url, {
            method,
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': authToken
            },
            body: characterString
          });
          
          if (response.ok) {
            const result = await response.json();
            console.log('Server save successful:', result);
            setDebugInfo(prevDebug => prevDebug + '\nCharacter saved successfully to server');
            
            // Update the character with the server ID
            if (isNew && result.id && !result.id.toString().startsWith('demo-')) {
              characterToSave.id = result.id;
              setCharacter({
                ...characterToSave,
                id: result.id
              });
            }
            
            // IMPORTANT: Clear any previous localStorage version to force using
            // server data on next load
            localStorage.removeItem(storageKey);
            
            // Then save the updated version to localStorage
            localStorage.setItem(storageKey, JSON.stringify({
              ...characterToSave,
              id: result.id || characterToSave.id,
              lastUpdated: new Date().toISOString() // Add timestamp for tracking
            }));
            
            setUnsavedChanges(false);
            alert('Character saved successfully to server!');
            return; // Early return if server save successful
          } else {
            throw new Error(`Server returned ${response.status}`);
          }
        } catch (serverError) {
          console.error('Server save failed:', serverError);
          setDebugInfo(prevDebug => prevDebug + `\nServer save failed: ${serverError.message}, falling back to localStorage only`);
          alert(`Warning: Server save failed. Changes will be saved locally but may not appear on other devices. Error: ${serverError.message}`);
          // Continue to save to localStorage as fallback
        }
      }
      
      // If server save failed or no auth token, save to localStorage only
      console.log(`Saving character to localStorage with key: ${storageKey}`);
      setDebugInfo(prevDebug => prevDebug + `\nSaving to localStorage only with key: ${storageKey}`);
      
      try {
        const characterJson = JSON.stringify({
          ...characterToSave,
          lastUpdated: new Date().toISOString() // Add timestamp for tracking
        });
        localStorage.setItem(storageKey, characterJson);
        console.log(`Character "${characterToSave.name || 'Unnamed'}" saved to localStorage (${characterJson.length} bytes)`);
        
        setCharacter(characterToSave);
        setUnsavedChanges(false);
        
        if (!authToken) {
          alert('Character saved to browser storage! (No server save - not logged in)');
        }
      } catch (storageErr) {
        console.error('Error saving to localStorage:', storageErr);
        setDebugInfo(prevDebug => prevDebug + `\nError saving to localStorage: ${storageErr.message}`);
        throw storageErr; // Re-throw to be caught by outer catch block
      }
    } catch (err) {
      console.error('Error saving character:', err);
      setDebugInfo(prevDebug => prevDebug + `\nError saving character: ${err.message}`);
      
      // Check if it might be a payload size issue
      const characterSize = JSON.stringify(character).length;
      if (characterSize > 1000000) { // 1MB
        alert(`Failed to save character: The data is too large (${(characterSize/1024/1024).toFixed(2)}MB). Try reducing the amount of data or contact your administrator.`);
      } else {
        alert(`Failed to save character: ${err.message}`);
      }
    }
  };
  
  const exportCharacter = () => {
    const dataStr = JSON.stringify(character, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    
    const exportFileDefaultName = `${character.name || 'character'}.json`;
    
    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };
  
  const importCharacter = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);
        setCharacter(data);
        setUnsavedChanges(true);
      } catch (err) {
        console.error('Error parsing JSON:', err);
        alert('Invalid JSON file. Please upload a valid character file.');
      }
    };
    reader.readAsText(file);
  };
  
  if (loading) {
    return <div className="character-sheet-loading">Loading character data...</div>;
  }
  
  if (error && !character) {
    return (
      <div className="character-sheet-error">
        <div>{error}</div>
        <div className="debug-info">
          <h4>Debug Information:</h4>
          <pre>{debugInfo}</pre>
        </div>
      </div>
    );
  }
  
  if (!character) {
    return (
      <div className="character-sheet-error">
        <div>No character data available.</div>
        <div className="debug-info">
          <h4>Debug Information:</h4>
          <pre>{debugInfo}</pre>
        </div>
      </div>
    );
  }
  
  return (
    <div className="character-sheet-container">
      <div className="character-sheet-header">
        <h2>{character.name || 'New Character'}</h2>
        <div className="character-sheet-actions">
          <button 
            className="save-button"
            onClick={saveCharacter}
            disabled={!unsavedChanges}
          >
            Save Character
          </button>
          <button 
            className="export-button"
            onClick={exportCharacter}
          >
            Export JSON
          </button>
          <label className="import-button">
            Import JSON
            <input 
              type="file" 
              accept=".json" 
              onChange={importCharacter} 
              style={{ display: 'none' }} 
            />
          </label>
        </div>
      </div>
      
      <div className="character-sheet-tabs">
        <button 
          className={`tab-button ${activeTab === 'info' ? 'active' : ''}`} 
          onClick={() => setActiveTab('info')}
        >
          Character Info
        </button>
        <button 
          className={`tab-button ${activeTab === 'abilities' ? 'active' : ''}`} 
          onClick={() => setActiveTab('abilities')}
        >
          Abilities & Skills
        </button>
        <button 
          className={`tab-button ${activeTab === 'combat' ? 'active' : ''}`} 
          onClick={() => setActiveTab('combat')}
        >
          Combat
        </button>
        <button 
          className={`tab-button ${activeTab === 'equipment' ? 'active' : ''}`} 
          onClick={() => setActiveTab('equipment')}
        >
          Equipment
        </button>
        <button 
          className={`tab-button ${activeTab === 'features' ? 'active' : ''}`} 
          onClick={() => setActiveTab('features')}
        >
          Features & Traits
        </button>
      </div>
      
      <div className="character-sheet-content">
        {activeTab === 'info' && (
          <div className="character-info-tab">
            <div className="character-section">
              <h3>Basic Information</h3>
              <div className="character-fields">
                <div className="field-group">
                  <label>Character Name</label>
                  <input 
                    type="text" 
                    value={character.name || ''} 
                    onChange={(e) => handleInputChange('', 'name', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Class</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.originalClass || ''} 
                    onChange={(e) => handleInputChange('system.details', 'originalClass', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Level</label>
                  <input 
                    type="number" 
                    value={character.system?.details?.xp?.value || 0} 
                    onChange={(e) => handleInputChange('system.details.xp', 'value', parseInt(e.target.value))} 
                  />
                </div>
                <div className="field-group">
                  <label>Race</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.race || ''} 
                    onChange={(e) => handleInputChange('system.details', 'race', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Background</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.background || ''} 
                    onChange={(e) => handleInputChange('system.details', 'background', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Alignment</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.alignment || ''} 
                    onChange={(e) => handleInputChange('system.details', 'alignment', e.target.value)} 
                  />
                </div>
              </div>
            </div>
            
            <div className="character-section">
              <h3>Appearance</h3>
              <div className="character-fields">
                <div className="field-group">
                  <label>Gender</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.gender || ''} 
                    onChange={(e) => handleInputChange('system.details', 'gender', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Age</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.age || ''} 
                    onChange={(e) => handleInputChange('system.details', 'age', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Height</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.height || ''} 
                    onChange={(e) => handleInputChange('system.details', 'height', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Weight</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.weight || ''} 
                    onChange={(e) => handleInputChange('system.details', 'weight', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Eyes</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.eyes || ''} 
                    onChange={(e) => handleInputChange('system.details', 'eyes', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Hair</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.hair || ''} 
                    onChange={(e) => handleInputChange('system.details', 'hair', e.target.value)} 
                  />
                </div>
                <div className="field-group">
                  <label>Skin</label>
                  <input 
                    type="text" 
                    value={character.system?.details?.skin || ''} 
                    onChange={(e) => handleInputChange('system.details', 'skin', e.target.value)} 
                  />
                </div>
              </div>
            </div>
            
            <div className="character-section">
              <h3>Personality</h3>
              <div className="character-fields">
                <div className="field-group full-width">
                  <label>Personality Traits</label>
                  <textarea 
                    value={character.system?.details?.trait || ''} 
                    onChange={(e) => handleInputChange('system.details', 'trait', e.target.value)} 
                  />
                </div>
                <div className="field-group full-width">
                  <label>Ideals</label>
                  <textarea 
                    value={character.system?.details?.ideal || ''} 
                    onChange={(e) => handleInputChange('system.details', 'ideal', e.target.value)} 
                  />
                </div>
                <div className="field-group full-width">
                  <label>Bonds</label>
                  <textarea 
                    value={character.system?.details?.bond || ''} 
                    onChange={(e) => handleInputChange('system.details', 'bond', e.target.value)} 
                  />
                </div>
                <div className="field-group full-width">
                  <label>Flaws</label>
                  <textarea 
                    value={character.system?.details?.flaw || ''} 
                    onChange={(e) => handleInputChange('system.details', 'flaw', e.target.value)} 
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'abilities' && (
          <div className="abilities-tab">
            <div className="ability-scores">
              <h3>Ability Scores</h3>
              <div className="ability-grid">
                {['str', 'dex', 'con', 'int', 'wis', 'cha'].map(ability => {
                  const abilityData = character.system?.abilities?.[ability] || { value: 10 };
                  const modifier = Math.floor((abilityData.value - 10) / 2);
                  
                  // Map abbreviated ability names to full names
                  const abilityFullNames = {
                    str: 'Strength',
                    dex: 'Dexterity',
                    con: 'Constitution', 
                    int: 'Intelligence',
                    wis: 'Wisdom',
                    cha: 'Charisma'
                  };
                  
                  return (
                    <div key={ability} className="ability-box">
                      <div className="ability-name">
                        {abilityFullNames[ability]}
                      </div>
                      <input 
                        type="number" 
                        className="ability-value" 
                        value={abilityData.value || 10} 
                        onChange={(e) => handleInputChange(`system.abilities.${ability}`, 'value', parseInt(e.target.value))} 
                      />
                      <div className="ability-modifier">
                        {modifier >= 0 ? `+${modifier}` : modifier}
                      </div>
                      <div className="ability-save">
                        <label>
                          <input 
                            type="checkbox" 
                            checked={abilityData.proficient === 1} 
                            onChange={(e) => handleInputChange(`system.abilities.${ability}`, 'proficient', e.target.checked ? 1 : 0)} 
                          />
                          Save
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            
            <div className="skills-section">
              <h3>Skills</h3>
              <div className="skills-list">
                {Object.entries(character.system?.skills || {}).map(([skillId, skill]) => {
                  if (typeof skill !== 'object' || !skill.ability) return null;
                  
                  const abilityMod = Math.floor((character.system?.abilities?.[skill.ability]?.value - 10) / 2) || 0;
                  const profBonus = 2; // This would normally be calculated based on level
                  const skillMod = abilityMod + (skill.value ? profBonus : 0);
                  
                  // Map skill abbreviations to full names
                  const skillFullNames = {
                    acr: 'Acrobatics',
                    ani: 'Animal Handling',
                    arc: 'Arcana',
                    ath: 'Athletics',
                    dec: 'Deception',
                    his: 'History',
                    ins: 'Insight',
                    itm: 'Intimidation',
                    inv: 'Investigation',
                    med: 'Medicine',
                    nat: 'Nature',
                    prc: 'Perception',
                    prf: 'Performance',
                    per: 'Persuasion',
                    rel: 'Religion',
                    slt: 'Sleight of Hand',
                    ste: 'Stealth',
                    sur: 'Survival',
                    tech: 'Technology'
                  };
                  
                  // Map abbreviated ability names to full names
                  const abilityFullNames = {
                    str: 'STR',
                    dex: 'DEX',
                    con: 'CON', 
                    int: 'INT',
                    wis: 'WIS',
                    cha: 'CHA'
                  };
                  
                  return (
                    <div key={skillId} className="skill-item">
                      <label>
                        <input 
                          type="checkbox" 
                          checked={skill.value === 1} 
                          onChange={(e) => handleInputChange(`system.skills.${skillId}`, 'value', e.target.checked ? 1 : 0)} 
                        />
                        <span className="skill-name">
                          {skillFullNames[skillId] || skillId.charAt(0).toUpperCase() + skillId.slice(1)} ({abilityFullNames[skill.ability] || skill.ability.toUpperCase()})
                        </span>
                        <span className="skill-mod">
                          {skillMod >= 0 ? `+${skillMod}` : skillMod}
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'combat' && (
          <div className="combat-tab">
            <div className="combat-basics">
              <div className="combat-stat hp">
                <label>Hit Points</label>
                <input 
                  type="number" 
                  value={character.system?.attributes?.hp?.value || 0} 
                  onChange={(e) => handleInputChange('system.attributes.hp', 'value', parseInt(e.target.value))} 
                />
                <span>/ {character.system?.attributes?.hp?.max || 0}</span>
                <input 
                  type="number" 
                  className="max-hp" 
                  value={character.system?.attributes?.hp?.max || 0} 
                  onChange={(e) => handleInputChange('system.attributes.hp', 'max', parseInt(e.target.value))} 
                />
              </div>
              
              <div className="combat-stat ac">
                <label>Armor Class</label>
                <input 
                  type="number" 
                  value={character.system?.attributes?.ac?.flat || 10} 
                  onChange={(e) => handleInputChange('system.attributes.ac', 'flat', parseInt(e.target.value))} 
                />
              </div>
              
              <div className="combat-stat initiative">
                <label>Initiative</label>
                <input 
                  type="text" 
                  value={character.system?.attributes?.init?.bonus || ''} 
                  onChange={(e) => handleInputChange('system.attributes.init', 'bonus', e.target.value)} 
                />
              </div>
              
              <div className="combat-stat speed">
                <label>Speed</label>
                <input 
                  type="number" 
                  value={character.system?.attributes?.movement?.walk || 30} 
                  onChange={(e) => handleInputChange('system.attributes.movement', 'walk', parseInt(e.target.value))} 
                />
                <span>ft.</span>
              </div>
            </div>
            
            <div className="attacks-section">
              <h3>Attacks & Spellcasting</h3>
              <div className="attacks-list">
                {character.items?.filter(item => item.type === 'weapon').map((weapon, index) => (
                  <div key={weapon._id || index} className="attack-item">
                    <div className="weapon-name">{weapon.name}</div>
                    <div className="weapon-atk">
                      {(() => {
                        const abilityMod = Math.floor((character.system?.abilities?.['str']?.value - 10) / 2) || 0;
                        const profBonus = 2; // This would normally be calculated based on level
                        const attackBonus = abilityMod + profBonus + (weapon.system?.magicalBonus || 0);
                        return attackBonus >= 0 ? `+${attackBonus}` : attackBonus;
                      })()}
                    </div>
                    <div className="weapon-damage">
                      {weapon.system?.damage?.base?.number || 1}
                      {weapon.system?.damage?.base?.denomination ? `d${weapon.system?.damage?.base?.denomination}` : ''}
                      {(() => {
                        const abilityMod = Math.floor((character.system?.abilities?.['str']?.value - 10) / 2) || 0;
                        return abilityMod >= 0 ? ` + ${abilityMod}` : ` ${abilityMod}`;
                      })()}
                      {' '}
                      {weapon.system?.damage?.base?.types?.join(', ') || ''}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'equipment' && (
          <div className="equipment-tab">
            <div className="currency-section">
              <h3>Currency</h3>
              <div className="currency-grid">
                <div className="currency-item">
                  <label>PP</label>
                  <input 
                    type="number" 
                    value={character.system?.currency?.pp || 0} 
                    onChange={(e) => handleInputChange('system.currency', 'pp', parseInt(e.target.value))} 
                  />
                </div>
                <div className="currency-item">
                  <label>GP</label>
                  <input 
                    type="number" 
                    value={character.system?.currency?.gp || 0} 
                    onChange={(e) => handleInputChange('system.currency', 'gp', parseInt(e.target.value))} 
                  />
                </div>
                <div className="currency-item">
                  <label>EP</label>
                  <input 
                    type="number" 
                    value={character.system?.currency?.ep || 0} 
                    onChange={(e) => handleInputChange('system.currency', 'ep', parseInt(e.target.value))} 
                  />
                </div>
                <div className="currency-item">
                  <label>SP</label>
                  <input 
                    type="number" 
                    value={character.system?.currency?.sp || 0} 
                    onChange={(e) => handleInputChange('system.currency', 'sp', parseInt(e.target.value))} 
                  />
                </div>
                <div className="currency-item">
                  <label>CP</label>
                  <input 
                    type="number" 
                    value={character.system?.currency?.cp || 0} 
                    onChange={(e) => handleInputChange('system.currency', 'cp', parseInt(e.target.value))} 
                  />
                </div>
              </div>
            </div>
            
            <div className="equipment-list">
              <h3>Equipment</h3>
              <div className="items-list">
                {character.items?.filter(item => ['weapon', 'equipment', 'loot', 'consumable'].includes(item.type)).map((item, index) => (
                  <div key={item._id || index} className="equipment-item">
                    <div className="item-name">{item.name}</div>
                    <div className="item-qty">
                      <input 
                        type="number" 
                        value={item.system?.quantity || 1} 
                        onChange={(e) => {
                          const updatedItems = [...character.items];
                          updatedItems[character.items.findIndex(i => i._id === item._id)].system.quantity = parseInt(e.target.value);
                          setCharacter({...character, items: updatedItems});
                          setUnsavedChanges(true);
                        }} 
                        min="0"
                      />
                    </div>
                    <div className="item-weight">{item.system?.weight?.value || 0} lb.</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        
        {activeTab === 'features' && (
          <div className="features-tab">
            <div className="features-section">
              <h3>Features & Traits</h3>
              <div className="features-list">
                {character.items?.filter(item => item.type === 'feat').map((feature, index) => (
                  <div key={feature._id || index} className="feature-item">
                    <div className="feature-header">
                      <h4 className="feature-name">{feature.name}</h4>
                      <div className="feature-source">{feature.system?.source?.book || ''}</div>
                    </div>
                    <div 
                      className="feature-description" 
                      dangerouslySetInnerHTML={{ 
                        __html: formatDndReferences(feature.system?.description?.value || '') 
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CharacterSheet; 